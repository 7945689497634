@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Jomhuria&family=Roboto:wght@300;400&display=swap'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* https://cssgradient.io/ */
  background: rgb(2, 11, 18);
  /* background: linear-gradient(180deg, rgba(2, 11, 18, 1) 0%, rgba(2, 35, 47, 1) 47%, rgba(0, 60, 74, 1) 100%); */
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ytp-large-play-button.ytp-button {
  display: none;
}
